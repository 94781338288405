require('./bootstrap');
require('livewire-sortable');
import Splide from '@splidejs/splide';
import collapse from '@alpinejs/collapse';
window.Splide = Splide;
import Alpine from 'alpinejs';
Alpine.plugin(collapse);
window.Alpine = Alpine;

Alpine.start();

